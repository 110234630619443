import React, { useEffect, useState } from 'react';
import Title from '../../extra/Title';
import Table from '../../extra/Table';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../extra/Pagination';
import Searching from '../../extra/Searching';
import Button from '../../extra/Button';
import { closeDialog, openDialog } from '../../../redux/slice/dialogSlice';
import { warning } from '../../util/Alert';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteProduct, deleteProductColor, productGet, updateMultiProduct } from '../../../redux/slice/productSlice';
import ToggleSwitch from '../../extra/ToggleSwitch';
import { orderAll, updateOrderStatus } from '../../../redux/slice/orderSlice';
import OrderShow from './OrderShow';
import { baseURL } from '../../util/config';

const Order = () => {
  const dispatch = useDispatch()


  const { dialogue, dialogueType } = useSelector(
    (state) => state.dialogue
  );




  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [data, setData] = useState([]);

  const { order, orderTotal } = useSelector((state) => state.order)

  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };



  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState(0);
  const handleChildValue = (value) => {
    setSortOrder(sortOrder === 0 ? 1 : 0);
    setSortField(value);
  };

  const payload = {
    page,
    limit: rowsPerPage,
    search,
    status: "",
    sortField,
    sortOrder
  }
  // Server Get
  useEffect(() => {
    dispatch(orderAll({ ...payload, command: false }))
  }, [page, rowsPerPage, search, sortField, sortOrder]);

  // normal Get
  useEffect(() => {
    dispatch(orderAll({ ...payload, command: true }))
  }, []);

  useEffect(() => {
    setData(order)
  }, [order]);




  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };



  const handleChangeStatus = (orderId, status) => {
    dispatch(updateOrderStatus({ orderId, status }))
  }

  const orderTable = [
    {
      Header: "No",
      body: "No",
      Cell: ({ index }) => (
        <span>{page * rowsPerPage + parseInt(index) + 1}</span>
      )
    },
    {
      Header: "Product",
      body: "productImage",
      Cell: ({ row }) => (
        <span className='d-flex align-items-center'>
          <div className="userProfile hw-80 m10-right" style={{ height: "80px", width: "80px", overflow: "hidden" }}>
            {row?.productImage && <img src={baseURL + row?.productImage[0] || []} alt="image" height={`100%`} />}
          </div>
          <span>
            <span className='space-wrap text-overflow-2'>{row.title}</span>
            <div className={`text-light d-inline-block p1-y p5-x fs-12 m5-top ${row?.confirmStatus == 1 ? "bg-warning" : row?.confirmStatus == 2 ? "bg-info" : row?.confirmStatus == 3 ? "bg-success" : row?.confirmStatus == 4 ? "bg-danger" : row?.confirmStatus == 5 && "bg-third"}`}>{row?.confirmStatus == 1 ? "Pendding" : row?.confirmStatus == 2 ? "Confirm" : row?.confirmStatus == 3 ? "Deliverd" : row?.confirmStatus == 4 ? "Canceled" : row?.confirmStatus == 5 && "Return"}</div>
          </span>
        </span>
      ),
    },
    { Header: "Order Id", body: "orderId", sorting: { type: "client" } },
    {
      Header: "Method",
      body: "isCod",
      Cell: ({ row }) => (
        <span>{row.isCod ? "COD" : "ONLINE"}</span>
        ),
        sorting: { type: "client" },
        
      },
    
    { Header: "Product Code", body: "productCode", sorting: { type: "client" } },
    { Header: "Product", body: "productCount", sorting: { type: "client" } },
    {
      Header: "Old Price",
      body: "oldPrice",
      Cell: ({ row }) => (
        <span>₹{row.oldPrice}</span>
      ),
      sorting: { type: "client" },

    },
    {
      Header: "Price",
      body: "price",
      Cell: ({ row }) => (
        <span>₹{row.price}</span>
      ),
      sorting: { type: "client" }
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <button className='bg-primary m15-right text-light p10-x p7-y fs-14 position-relative' onClick={() => dispatch(openDialog({ type: "orderInfo", data: row }))}>
            <i class="ri-information-line"></i>
          </button>
        </span>
      ),
    },
    {
      Header: "Status",
      Cell: ({ row }) => (
        <span>
          {
            row?.confirmStatus > 1 && row?.confirmStatus < 5 &&
            <button className='bg-warning m15-right text-light p10-x p7-y fs-14 position-relative' onClick={() => handleChangeStatus(row?._id, 1)}>
              Undo
            </button>
          }
          {
            row?.confirmStatus == 1 &&
            <button className='bg-info m15-right text-light p10-x p7-y fs-14 position-relative' onClick={() => handleChangeStatus(row?._id, 2)}>
              Confirm
            </button>
          }
          {
            row?.confirmStatus == 2 &&
            <button className='bg-success m15-right text-light p10-x p7-y fs-14 position-relative' onClick={() => handleChangeStatus(row?._id, 3)}>
              Deliverd
            </button>
          }

          {
            row?.confirmStatus < 3 &&
            <button className='bg-danger m15-right text-light p10-x p7-y fs-14 position-relative' onClick={() => handleChangeStatus(row?._id, 4)}>
              Cancel
            </button>
          }
        </span>
      ),
    },
    { Header: "Payment Order Id", body: "paymentOrderId", sorting: { type: "client" } },
    { Header: "Payment  Id", body: "paymentId", sorting: { type: "client" } },


  ];

  const navigation = useNavigate()

  return (
    <div>
      <Title name={"Order"} />

      <div className='bg-light p15'>
        <div className="row justify-content-between align-items-center">
          <div className="col-2 m0">
            {/* <Button className={`bg-second text-light`} text={`ADD`} bIcon={`ri-add-line`}
              onClick={() => {
                navigation("/admin/product/addProduct")
              }}
            /> */}
          </div>
          <div className="col-md-5 col-smm-6 col-7 m0 ">
            <Searching
              type={`server`}
              data={order}
              setData={setData}
              column={orderTable}
              serverSearching={handleFilterData}
            />
          </div>
        </div>
      </div>
      <Table
        data={data}
        mapData={orderTable}
        serverPerPage={rowsPerPage}
        Page={page}
        onChildValue={handleChildValue}
      />
      <Pagination
        type={"server"}
        serverPage={page}
        setServerPage={setPage}
        serverPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        totalData={orderTotal}
      />


      {dialogue && dialogueType === "orderInfo" && (
        <OrderShow />
      )}
    </div>
  );
}

export default Order;
